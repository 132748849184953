import React from "react"
import Layout from "../layouts/en"
import SEO from "../layouts/seo"
import Home from "../components/home"

const HomePage = props => {
  return (
    <Layout location={props.location} pageType="home">
      <SEO title="Home" lang="en" />
      <Home langKey="en"></Home>
    </Layout>
  )
}

export default HomePage
